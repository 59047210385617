import {
  ApolloClient,
  InMemoryCache,
  from,
} from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { setContext } from '@apollo/link-context';

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token') || '';

  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : '',
      'x-client': 'encore',
    },
  };
});

const uploadLink = createUploadLink({
  uri: process.env.REACT_APP_APOLLO_CLIENT_URI,
  headers: {
    'x-client': 'encore',
  },
});

const client = new ApolloClient({
  link: from([authLink, uploadLink]),
  cache: new InMemoryCache({
    typePolicies: {
      ParagraphType: {
        keyFields: ['uuid'],
      },
    },
  }),
});

export default client;
